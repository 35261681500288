import React, { useEffect, useState } from 'react';
// import logo from './logo.svg';
import './App.css';
import { Login } from './login';

function App() {
  // const [result, setResult] = useState("calling api ...");
  // useEffect(() => {
  //   (async () => {
  //     const res = await fetch("/api/ping");
  //     setResult(`API result: ${await res.text()}`);
  //   })();
  // }, [setResult]);

  return (
    <Login />
  );
}

export default App;
